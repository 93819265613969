import {
    userBalanceRequest,
    fetchUserCards,
    depositRequest,
    transactionStatusRequest,
    getPaymentTransaction,
    withdrawalRequest,
    cancelWithdrawalRequest,
    getPaymentConfig
} from '../../workers/bank';
import { finishSession } from './user';
import { useToken } from '../../components/utils/useToken';
import moment from "moment";

const WALLET_INFORMATION = 'WALLET_INFORMATION';
const DEFAULT = 'DEFAULT';
const GET_SOCKET_BALANCE = 'GET_SOCKET_BALANCE';
const SOCKET_REQUEST_FALSE = 'SOCKET_REQUEST_FALSE';
const SOCKET_REQUEST_TRUE = 'SOCKET_REQUEST_TRUE';
const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
const FETCH_PAYMENT_METHODS_FAIL = 'FETCH_PAYMENT_METHODS_FAIL';
const FETCH_USER_CARDS = 'FETCH_USER_CARDS';
const FETCH_USER_CARDS_SUCCESS = 'FETCH_USER_CARDS_SUCCESS';
const FETCH_USER_CARDS_FAIL = 'FETCH_USER_CARDS_FAIL';
const SUBMIT_FORM_DEPOSIT = 'SUBMIT_FORM_DEPOSIT';
const SUBMIT_FORM_DEPOSIT_SUCCESS = 'SUBMIT_FORM_DEPOSIT_SUCCESS';
const SUBMIT_FORM_DEPOSIT_FAIL = 'SUBMIT_FORM_DEPOSIT_FAIL';
const REMOVE_FORM_DEPOSIT_ERROR = 'REMOVE_FORM_DEPOSIT_ERROR';
const REMOVE_SERVER_ERROR = 'REMOVE_SERVER_ERROR';
const SELECT_BONUS = 'SELECT_BONUS';
const SET_LIMITS = 'SET_LIMITS';
const GET_PAYMENT_CMS_CONTENT = 'GET_PAYMENT_CMS_CONTENT';
const ADD_VOUCHER_NUMBER = 'ADD_VOUCHER_NUMBER';
const REMOVE_VOUCHER_NUMBER = 'REMOVE_VOUCHER_NUMBER';
const GET_TRANSACTION_STATUS = 'GET_TRANSACTION_STATUS';
const REMOVE_DEPOSIT_STATUSES = 'REMOVE_DEPOSIT_STATUSES';
const TRANSACTIONS_HISTORY_LOADING = 'TRANSACTIONS_HISTORY_LOADING';
const TRANSACTIONS_HISTORY_SUCCESS = 'TRANSACTIONS_HISTORY_SUCCESS';
const TRANSACTIONS_HISTORY_ERROR = 'TRANSACTIONS_HISTORY_ERROR';
const SET_ACTIVE_WALLET_TAB = 'SET_ACTIVE_WALLET_TAB';
const SUBMIT_FORM_WITHDRAWAL = 'SUBMIT_FORM_WITHDRAWAL';
const SUBMIT_FORM_WITHDRAWAL_SUCCESS = 'SUBMIT_FORM_WITHDRAWAL_SUCCESS';
const SUBMIT_FORM_WITHDRAWAL_FAIL = 'SUBMIT_FORM_WITHDRAWAL_FAIL';
const CANCEL_WITHDRAWAL_SUCCESS = 'CANCEL_WITHDRAWAL_SUCCESS';
const CANCEL_WITHDRAWAL_FAIL = 'CANCEL_WITHDRAWAL_FAIL';
const CLEANUP_WITHDRAWAL = 'CLEANUP_WITHDRAWAL';
const CLEANUP_CANCEL_WITHDRAWAL = 'CLEANUP_CANCEL_WITHDRAWAL';
const OPEN_WITHDRAWAL_BLOCKED_MODAL = 'OPEN_WITHDRAWAL_BLOCKED_MODAL';
const DEFAULT_BANK_STATE = 'DEFAULT_BANK_STATE';

const initialBankState = {
    balance: {},
    depositLimit: {
        day: '',
        week: '',
        month: ''
    },
    depositLimitRemaining: {
        day: '',
        week: '',
        month: ''
    },
    balanceIsLoaded: false,
    isSocketConnected: true,
    paymentMethods: [],
    paymentMethodsLoading: false,
    paymentMethodsError: false,
    cards: [],
    isCardsLoading: false,
    cardsError: null,
    submitFormDeposit: {
        isSubmit: false,
        isSuccess: false,
        isFail: false
    },
    formDepositFail: [],
    formDepositResponse: {
        url: '',
        type: '',
    },
    transactionStatus: {
        status: '',
        error: ''
    },
    selectedBonus: '',
    limits: {
        minimumDeposit: '',
        minimumWithdrawal: '',
        maximumWithdrawal: ''
    },
    amountList: ['50', '100', '200'],
    paymentCmsContent: {
        footer: '',
        rightSide: '',
        header: '',
    },
    voucherNumbers: ['defaultItem'],
    voucherMaxQuantity: 5,
    transactionsHistoryLoading: false,
    transactionsHistoryError: false,
    transactionsHistory: [],
    walletTabs: ['deposit', 'withdrawal'],
    activeWalletTab: 'deposit',
    submitFormWithdrawal: {
        isSubmit: false,
        isSuccess: false,
        isFail: false
    },
    withdrawalAmount: '',
    formWithdrawalFail: [],
    cancelWithdrawalSuccess: false,
    cancelWithdrawalError: false,
    withdrawalBlockedModal: false,
}

export const initialState = {
    ...initialBankState
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case WALLET_INFORMATION:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            return {
                ...state,
                balance: {
                    ...action.payload.data.balance,
                    timestamp: moment().unix(),
                },
                depositLimit: action.payload.data.depositLimit,
                depositLimitRemaining: action.payload.data.depositLimitRemaining,
                balanceIsLoaded: true
            };
        case GET_SOCKET_BALANCE:
            const shouldUpdate = !state.balance.timestamp || action.payload.timestamp > state.balance.timestamp;
            const balance = shouldUpdate ? action.payload : state.balance;
            return{
                ...state,
                balance: balance
            };
        case SOCKET_REQUEST_FALSE:
            return {
                ...state,
                isSocketConnected: false
            };
        case SOCKET_REQUEST_TRUE:
            return {
                ...state,
                isSocketConnected: true
            };
        case FETCH_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethodsLoading: true,
            };
        case FETCH_PAYMENT_METHODS_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            const mastercardMaestroMethod = {
                paymentMethod: 'mastercardMaestro',
                enabled: true
            };
            const cardTypes = action.cards.map(card => card.type);
            const sortedMethods = action.payload.data.sort((a, b) => cardTypes.indexOf(b.paymentMethod) - cardTypes.indexOf(a.paymentMethod));
            const mastercardMaestro = sortedMethods
                .map((method, index) => {
                    if ((method.paymentMethod === 'mastercard' || method.paymentMethod === 'maestro')) {
                        return {
                            ...method,
                            index
                        }
                    } else return method;
                })
                .filter(method => typeof method.index === 'number');
            const isMastercardMaestroIndexes = mastercardMaestro.map(method => method.index);
            const paymentMethods = mastercardMaestro.length === 2 ?
                [
                    ...sortedMethods.slice(0, Math.min(...isMastercardMaestroIndexes)),
                    mastercardMaestroMethod,
                    ...sortedMethods.slice(Math.min(...isMastercardMaestroIndexes) + 1)
                ].filter(method => !mastercardMaestro.some(item => method.paymentMethod === item.paymentMethod))
                : sortedMethods;
            return {
                ...state,
                paymentMethods,
                cards: action.cards,
                paymentMethodsLoading: false,
                paymentMethodsError: false,
            };
        case FETCH_PAYMENT_METHODS_FAIL:
            return {
                ...state,
                paymentMethodsLoading: false,
                paymentMethodsError: true,
            };
        case FETCH_USER_CARDS:
            return {
                ...state,
                isCardsLoading: true,
            };
        case FETCH_USER_CARDS_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            return {
                ...state,
                cards: action.payload.data,
                isCardsLoading: false,
            };
        case FETCH_USER_CARDS_FAIL:
            return {
                ...state,
                cardsError: action.payload,
                isCardsLoading: false,
            };
        case SUBMIT_FORM_DEPOSIT:
            return {
                ...state,
                submitFormDeposit: {
                    isSubmit: true,
                    isSuccess: false,
                    isFail: false
                }
            };
        case SUBMIT_FORM_DEPOSIT_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);

            const handlePaymentMethod = () => {
                if (action.payload.data.message) {
                    return {
                        transactionStatus: {
                            status: "success",
                            error: '',
                        },
                        formDepositResponse: {
                            url: '',
                            type: '',
                        }
                    }
                } else {
                    const type = action.method === 'ezeewallet' ? 'redirect' : 'iframe';
                    return {
                        formDepositResponse: {
                            ...action.payload.data,
                            type,
                        },
                    }
                }
            };
            return {
                ...state,
                submitFormDeposit: {
                    isSubmit: false,
                    isSuccess: true,
                    isFail: false
                },
                formDepositFail: [],
                ...handlePaymentMethod()
            };
        case SUBMIT_FORM_DEPOSIT_FAIL:
            if (!action.payload.errors.customError && action.payload.errors !== 500 && action.payload.authorization) {
                useToken('token', action.payload.authorization.token);
                useToken('refreshToken', action.payload.authorization.refresh_token);
                useToken('expires_in', action.payload.authorization.expires_in);
            }
            let error500 = [{
                status: '500',
                detail: ''
            }];
            let error = Array.isArray(action.payload.errors) ? action.payload.errors : [{
                status: action.payload.errors.status,
                detail: action.payload.errors.detail ? action.payload.errors.detail.match(/[a-zA-Z-0-9 :]+/g) : ''
            }];
            const isFiveHundred = action.payload.errors === 500 || action.payload.errors.status === 500;
            return {
                ...state,
                submitFormDeposit: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: true
                },
                formDepositFail: isFiveHundred ? error500 : error,
            };
        case REMOVE_FORM_DEPOSIT_ERROR:
            return {
                ...state,
                submitFormDeposit: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: false
                },
                formDepositFail: [],
                formDepositResponse: {
                    url: '',
                    type: '',
                }
            };
        case REMOVE_SERVER_ERROR:
            const cleanErrorArray = state.formDepositFail.filter(item => item.source?.pointer !== action.payload);
            return {
                ...state,
                formDepositFail: cleanErrorArray
            };
        case SELECT_BONUS:
            return {
                ...state,
                selectedBonus: action.payload
            };
        case SET_LIMITS:
            return {
                ...state,
                limits: action.payload,
            };
        case GET_PAYMENT_CMS_CONTENT:
            const pasteContent = (id) => {
                const content = action.payload.data.find(item => item.slug === id)?.content;
                return content === '<p><br></p>' ? '' : content;
            };
            return {
                ...state,
                paymentCmsContent: {
                    footer: pasteContent('payment_deposit_footer'),
                    rightSide: pasteContent('right_side_payment'),
                    header: pasteContent('header_payment'),
                }
            };
        case ADD_VOUCHER_NUMBER:
            const updatedVaucherNumbers = [...state.voucherNumbers, action.payload];
            const addVoucherNumbers = updatedVaucherNumbers.length > state.voucherMaxQuantity ? [...state.voucherNumbers] : updatedVaucherNumbers;
            return {
                ...state,
                voucherNumbers: addVoucherNumbers
            };
        case REMOVE_VOUCHER_NUMBER:
            const start = state.voucherNumbers.slice(0, action.payload);
            const end = state.voucherNumbers.slice(action.payload + 1);
            return {
                ...state,
                voucherNumbers: [...start, ...end]
            };
        case GET_TRANSACTION_STATUS:
            if (action.payload.data.authorization) {
                useToken('token', action.payload.data.authorization.token);
                useToken('refreshToken', action.payload.data.authorization.refresh_token);
                useToken('expires_in', action.payload.data.authorization.expires_in);
            }
            return {
                ...state,
                transactionStatus: {
                    status: action.payload.data.status,
                    error: action.payload.data.errors,
                },
                formDepositResponse: {
                    url: '',
                    type: '',
                }
            };
        case REMOVE_DEPOSIT_STATUSES:
            return {
                ...state,
                transactionStatus: {
                    status: '',
                    error: ''
                },
                formDepositResponse: {
                    url: '',
                    type: '',
                }
            };
        case TRANSACTIONS_HISTORY_LOADING:
            return {
                ...state,
                transactionsHistoryLoading: true
            };
        case TRANSACTIONS_HISTORY_SUCCESS:
            useToken('token', action.payload.authorization.token);
            useToken('refreshToken', action.payload.authorization.refresh_token);
            useToken('expires_in', action.payload.authorization.expires_in);
            const pendingWithdrawals = action.payload.data.transaction.filter(item => item.withdrawalRequest?.status === 'pending');
            const notPendingWithdrawals = action.payload.data.transaction.filter(item => item.withdrawalRequest?.status !== 'pending');
            return {
                ...state,
                transactionsHistoryError: false,
                transactionsHistoryLoading: false,
                transactionsHistory: [...pendingWithdrawals, ...notPendingWithdrawals]
            };
        case TRANSACTIONS_HISTORY_ERROR:
            return {
                ...state,
                transactionsHistoryError: true,
                transactionsHistoryLoading: false
            };
        case SET_ACTIVE_WALLET_TAB:
            return {
                ...state,
                activeWalletTab: action.payload
            };
        case SUBMIT_FORM_WITHDRAWAL:
            return {
                ...state,
                submitFormWithdrawal: {
                    isSubmit: true,
                    isSuccess: false,
                    isFail: false
                }
            };
        case SUBMIT_FORM_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                submitFormWithdrawal: {
                    isSubmit: false,
                    isSuccess: true,
                    isFail: false
                },
                withdrawalAmount: action.payload.amount,
                formWithdrawalFail: [],
            };
        case SUBMIT_FORM_WITHDRAWAL_FAIL:
            if (action.payload.authorization) {
                useToken('token', action.payload.authorization.token);
                useToken('refreshToken', action.payload.authorization.refresh_token);
                useToken('expires_in', action.payload.authorization.expires_in);
            }
            return {
                ...state,
                submitFormWithdrawal: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: true
                },
                formWithdrawalFail: action.payload.errors,
                withdrawalAmount: '',
            };
        case CANCEL_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                cancelWithdrawalSuccess: true,
                cancelWithdrawalError: false,
            };
        case CANCEL_WITHDRAWAL_FAIL:
            return {
                ...state,
                cancelWithdrawalSuccess: false,
                cancelWithdrawalError: true,
            };
        case CLEANUP_WITHDRAWAL:
            return {
                ...state,
                submitFormWithdrawal: {
                    isSubmit: false,
                    isSuccess: false,
                    isFail: false
                },
                formWithdrawalFail: [],
                withdrawalAmount: '',
            };
        case CLEANUP_CANCEL_WITHDRAWAL:
            return {
                ...state,
                cancelWithdrawalSuccess: false,
                cancelWithdrawalError: false,
            };
        case OPEN_WITHDRAWAL_BLOCKED_MODAL:
            return {
                ...state,
                withdrawalBlockedModal: action.payload,
            };
        case DEFAULT:
            return state;
        case DEFAULT_BANK_STATE:
            return initialBankState;
        default:
            return state
    }
}

export const userBalance = (payload) => {
    const success = (data) => ({
        type: WALLET_INFORMATION,
        payload: data
    });
    if (payload !== undefined) {
        return dispatch => {
            userBalanceRequest()
                .then(res => dispatch(success(res.data)))
                .catch(() => dispatch(finishSession()))
        }
    }
    return {
        type: DEFAULT
    }
};

export const socketBalance = (payload) => ({
    type: GET_SOCKET_BALANCE,
    payload
});

export const defaultBankState = () => ({
    type: DEFAULT_BANK_STATE
})

export const socketCheckFalse = () => ({
    type: SOCKET_REQUEST_FALSE
});

export const socketCheckTrue = () => ({
    type: SOCKET_REQUEST_TRUE
});

export const fetchPaymentMethods = () => {
    const loading = () => ({
        type: FETCH_PAYMENT_METHODS,
    });
    const success = (payload, cards) => ({
        type: FETCH_PAYMENT_METHODS_SUCCESS,
        payload,
        cards
    });
    const fail = () => ({
        type: FETCH_PAYMENT_METHODS_FAIL,
    });
    return dispatch => {
        dispatch(loading());
        getPaymentConfig('payin')
            .then(methods => fetchUserCards()
                .then(cards => dispatch(success(methods.data, cards.data?.data)))
                .catch(() => dispatch(success(methods.data, [])))
            )
            .catch(() => dispatch(fail()))
    }
};

export const getUserCards = () => {
    const success = (payload) => ({
        type: FETCH_USER_CARDS_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: FETCH_USER_CARDS_FAIL,
        payload
    });
    return dispatch => {
        dispatch({ type: FETCH_USER_CARDS });
        fetchUserCards()
            .then(res => dispatch(success(res.data)))
            .catch(err => dispatch(fail(err)))
    }
};

export const submitFormDeposit = (form) => {
    const loading = () => ({
        type: SUBMIT_FORM_DEPOSIT
    });
    const success = (payload, method) => ({
        type: SUBMIT_FORM_DEPOSIT_SUCCESS,
        payload,
        method
    });
    const fail = (payload) => ({
        type: SUBMIT_FORM_DEPOSIT_FAIL,
        payload
    });
    const customizeError = (err) => ({
        errors: {
            customError: true,
            status: err.status || 404,
            detail: err.statusText
        }
    });
    const method = form.paymentInformation.type;
    return dispatch => {
        dispatch(loading());
        depositRequest(form)
            .then(res => dispatch(success(res.data, method)))
            .catch(err => {
                if (err.errors) {
                    return dispatch(fail(err))
                }
                return dispatch(fail(customizeError(err)));
            })
    }
};

export const removeFormDepositError = () => ({
    type: REMOVE_FORM_DEPOSIT_ERROR
});

export const removeServerError = (payload) => ({
    type: REMOVE_SERVER_ERROR,
    payload
});

export const selectBonus = (payload) => ({
    type: SELECT_BONUS,
    payload
});

export const setLimits = (payload) => ({
    type: SET_LIMITS,
    payload
});

export const getPaymentCmsContent = (payload) => ({
    type: GET_PAYMENT_CMS_CONTENT,
    payload
});

export const addVoucherNumber = (payload) => ({
    type: ADD_VOUCHER_NUMBER,
    payload
});

export const removeVoucherNumber = (payload) => ({
    type: REMOVE_VOUCHER_NUMBER,
    payload
});

export const getTransactionStatus = (id) => {
    const success = (payload) => ({
        type: GET_TRANSACTION_STATUS,
        payload
    });
    return dispatch => transactionStatusRequest(id)
        .then(res => dispatch(success(res.data)))
        .catch(err => {
            if (err.data) {
                if (err.data.errors) return dispatch(success({data: {...err.data, status: 'failure'}}));
                return dispatch(success({data: {...err.data, status: 'failure', errors: err.statusText}}));
            }
        })
};

export const removeDepositStatuses = () => ({
    type: REMOVE_DEPOSIT_STATUSES
});

export const getTransactionsHistory = () => {
    const loading = () => ({
        type: TRANSACTIONS_HISTORY_LOADING,
    });
    const success = (data) => ({
        type: TRANSACTIONS_HISTORY_SUCCESS,
        payload: data
    });
    const fail = () => ({
        type: TRANSACTIONS_HISTORY_ERROR,
    });
    const params = {
        limit: 15,
        offset: 0,
        order: 'desc'
    };
    const queryParams = `offset=${params.offset}&limit=${params.limit}&order=${params.order}`;
    return dispatch => {
        dispatch(loading());
        getPaymentTransaction(queryParams)
            .then(res => dispatch(success(res.data)))
            .catch(() => dispatch(fail()))
    }
};

export const setActiveWalletTab = (payload) => ({
    type: SET_ACTIVE_WALLET_TAB,
    payload
});

export const submitWithdrawalForm = (payload, currency) => {
    const loading = () => ({
        type: SUBMIT_FORM_WITHDRAWAL
    });
    const success = (data) => ({
        type: SUBMIT_FORM_WITHDRAWAL_SUCCESS,
        payload: data
    });
    const fail = (error) => ({
        type: SUBMIT_FORM_WITHDRAWAL_FAIL,
        payload: error
    });
    return dispatch => {
        dispatch(loading());
        withdrawalRequest(payload)
            .then(res => {
                dispatch(success({ ...res.data, amount: `${payload.amount} ${currency}` }));
                dispatch(getTransactionsHistory());
            })
            .catch(err => dispatch(fail(err)))
    }
};

export const cleanUpWithdrawal = () => ({
    type: CLEANUP_WITHDRAWAL
});

export const cleanUpCancelWithdrawal = () => ({
    type: CLEANUP_CANCEL_WITHDRAWAL
});

export const cancelWithdrawal = (id) => {
    const success = () => ({
        type: CANCEL_WITHDRAWAL_SUCCESS,
    });
    const fail = () => ({
        type: CANCEL_WITHDRAWAL_FAIL,
    });
    return dispatch => {
        dispatch(cleanUpCancelWithdrawal());
        cancelWithdrawalRequest(id)
            .then(() => dispatch(success()))
            .catch(() => dispatch(fail()))
            .finally(() => dispatch(getTransactionsHistory()))
    }
};

export const openWithdrawalBlockedModal = (payload) => ({
    type: OPEN_WITHDRAWAL_BLOCKED_MODAL,
    payload
});
